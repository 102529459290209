export const ChicagoData = [
  {
    title: "Technical",
    detail:
      "Tomedes’ technical translators are experts from all types of industries, with the linguistic rigor to  boot. That’s why they can translate the most technical terminologies from one language to another. They’re versed with all their fields--be it rare languages and rare industries. Contact us.",
    link: "/technical-translation.php",
    subTitle: " Translation Services",
  },
  {
    title: "Document ",
    detail:
      "Documents translated by Tomedes have the expertise of linguists from all over the globe. For applications in universities and immigrant applications, we can translate your birth certificates, academic transcripts, and other essential documents. Get started now.",
    link: "/document-translation-services",
    subTitle: "Translation Services",
  },
  {
    title: "Legal",
    detail:
      "Don’t trust just any translator to translate your documents, except for legal translators. Our legal translators know criminal law, civic law, corporate law, and other fields your business may be in. Contact us today to get in touch with our large network of legal translators.",
    link: "/legal-translation.php",
    subTitle: "Translation Services",
  },
  {
    title: "Medical ",
    detail:
      "For the medical field, there are many records, patient information, and other medical documents to be translated. And for patient interactions, you need a reliable medical interpreter, as well as a medical transcriptionist. Why not get in touch with Tomedes today?",
    link: "/medical-translation.php",
    subTitle: "Translation Services",
  },
];

export const LanguagesInChicago = [
  {
    heading: "Russian",
    detail:
      "Russian is widely spoken in Chicago, with a percentage of the population limited English speakers. That's why translations are necessary in a place like Chicago. Whether you need English to Russian translation services, Russian to English, or Russian to any other language, Tomedes is highly equipped to give quality service. Go with Tomedes.",
    link: "/languages/russian",
  },
  {
    heading: "Spanish",
    detail:
      " A huge number of Chicago's population speaks Spanish, with Spanish being the number one language spoken at home in Chicago. Translation services can better serve the community of Chicago with Spanish to English or vice versa translations for businesses or individual clients. Reach out for more.",
    link: "/spanish-translation.php",
  },
  {
    heading: "French",
    detail:
      "A percentage of the population speaks French in Chicago, and is also a common language for business and governments throughout the world. Translation services can boost your French translations with any of our other 950+ language pairs. Contact us today.",
    link: "/languages/french",
  },
  {
    heading: "Hungarian",
    detail:
      "Hungarian is a commonly spoken language in the Chicago area. In order to fully maximize communications internally and externally from Hungarian to any other language, use translations. Transform yourself and your industry with Tomedes.",
    link: "/languages/hungarian",
  },
];

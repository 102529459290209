import React from "react";
import SEO from "components/seo";
import Layout from "components/layout";
import Chicago from "components/locationIndexPages/Chicago/index";

function index() {
  const title = "Chicago Translation Services - Translation Agency";
  const description =
    "If you need high-quality translation services, Chicago is the place to be. Tomedes provides everything you need, from business translation to website localization.";
  const keywords = "Translation Services in Chicago";
  return (
    <Layout>
      <SEO
        title={title}
        description={description}
        keywords={keywords}
        slug="/locations/chicago-translation-services"
      />
      <Chicago />
    </Layout>
  );
}

export default index;
